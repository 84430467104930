import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const BACKEND_URL = environment.apiUrl;

export interface Teams {
  _id: string;
  name: string;
  teams: any;
  company: any;
}

export interface Users {
  _id: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  agent: string;
  role: string;
}

export interface Agents {
  _id: string;
  agentId: number;
  name: string;
  phone: number;
  email: string;
  token: string;
  resetToken: string,
  resetTokenDate: Date,
  hidden: boolean,
}

export interface Category {
  _id: string;
  links: any;
  teams: any;
  name: string;
}

export interface Links {
  _id: string;
  title: any;
}

export interface Integration {
  tokenPipedrive: string;
  statusPipedrive: boolean;
}

export interface User {
  name: string;
  phone: string;
  email: string;
  password: string;
}

export interface People {
  agentName: string;
  personId: number;
  dealId: number;
  name: string;
  phone: string;
  createdDate: Date;
}

export interface Landing {
  name: string;
  url: string;
  product: number;
  images: boolean;
  published: boolean;
  videos: boolean;
}

export interface Logs {
  type: string;
  name: string;
  date: string;
  lead: any;
  user: any;
  agent: any;
  detail: any;
  createdDate: Date;
  totalCount: any;
  stage: any;
  deals: any;    
}
export interface Offers {
  offerID: string;
  name: string,
  validDate: Date,
  currency: string,
  totalAmount: number,
  downPayment: number,
  balanceAmount: number,
  hospitalStay: string,
  operation: string,
  operationOther: string,
  anaesthesia: string,
  wellBeingAssistance: boolean,
  nursingQuantity: string,
  accommodation: boolean,
  hotel: string,
  room: string,
  roomDetail: string,
  roomPrice:  number,
  hotelNight: string,
  breakfast: boolean,
  attendant: boolean,
  transferStatus: boolean,
  transferPrice: number,
  transferPackage: boolean,
  otherTransfer: string,
  agent: any;
  createDate: Date;  
}

export interface LogsWPagination {
  logs: Logs[],
  currentPage: string,
  totalPages: number,
  totalRecords: number
}

export interface PeoplesWPagination {
  peoples: People[],
  currentPage: string,
  totalPages: number,
  totalRecords: number
}
export interface OffersWPagination {
  offers: Offers[],
  currentPage: string,
  totalPages: number,
  totalRecords: number
}

export interface searchLogs {
  logs: Logs[],
  currentPage: string,
  totalPages: number,
  totalRecords: number,
  searchInputs: any
}

export interface searchPeoples {
  peoples: People[],
  currentPage: string,
  totalPages: number,
  totalRecords: number,
  searchInputs: any
}

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) {
  }

  getAgents(): Observable<Agents[]> {
    return this.http.get<Agents[]>(BACKEND_URL + '/admin/agent/all');
  }

  updateAgent(data: any, id: any): Observable<Agents[]> {
    return this.http.put<Agents[]>(BACKEND_URL + '/admin/agent/update/' + id, data);
  }

  checkAgentfAdmin(data: any, id: any): Observable<Agents[]> {
    return this.http.put<Agents[]>(BACKEND_URL + '/admin/agent/check/' + id, data);
  }

  deleteAgent(agentId: any): Observable<Agents[]> {
    return this.http.delete<Agents[]>(BACKEND_URL + '/admin/agent/' + agentId);
  }

  checkAgents(): Observable<Agents[]> {
    return this.http.get<Agents[]>(BACKEND_URL + '/v1/agent/checkAgent/');
  }

  getPipelines(): Observable<any> {
    return this.http.get<any>(BACKEND_URL + '/admin/pipelines/all');
  }

  getTeams(): Observable<Teams[]> {
    return this.http.get<Teams[]>(BACKEND_URL + '/admin/teams/all');
  }

  getLandings(): Observable<Landing[]> {
    return this.http.get<Landing[]>(BACKEND_URL + '/admin/landings/all');
  }

  createLanding(data: any): Observable<Landing[]> {
    return this.http.post<Landing[]>(BACKEND_URL + '/admin/landing', data);
  }

  updateLanding(data: any): Observable<Landing[]> {
    return this.http.put<Landing[]>(BACKEND_URL + '/admin/landings', data);
  }

  updateOffer(data: any): Observable<Offers[]> {
    return this.http.put<Offers[]>(BACKEND_URL + '/admin/offers', data);
  }

  deleteLanding(data: any): Observable<Teams[]> {
    return this.http.delete<Teams[]>(BACKEND_URL + '/admin/landings/' + data);
  }

  getLogs(): Observable<Logs[]> {
    return this.http.get<Logs[]>(BACKEND_URL + '/admin/logs/all');
  }

  getLeadswTeam(searchInput: any): Observable<Logs[]> {
    let startDate = '';
    let endDate = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1].getTime() + (24 * 60 * 60 * 1000) - 1000);
          endDate = endDateTime.toISOString();
        }
        if (startDate && !endDate) {
          const startDateTime = new Date(searchInput.date[0]);
          endDate = new Date(startDateTime.getTime() + 24 * 60 * 60 * 1000 - 1000).toISOString();
        }
      }
    return this.http.get<Logs[]>(BACKEND_URL + '/admin/logs/leadswteam?startDate=' + startDate + '&endDate=' + endDate);
  }

  getDeposit(searchInput: any): Observable<Logs[]> {
    let startDate = '';
    let endDate = '';
    let id = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1].getTime() + (24 * 60 * 60 * 1000) - 1000);
          endDate = endDateTime.toISOString();
        }
        if (startDate && !endDate) {
          const startDateTime = new Date(searchInput.date[0]);
          endDate = new Date(startDateTime.getTime() + 24 * 60 * 60 * 1000 - 1000).toISOString();
        }      
      }
      if(searchInput.id){
        id = searchInput.id
      }
    return this.http.get<Logs[]>(BACKEND_URL + '/admin/logs/depositchart?startDate=' + startDate + '&endDate=' + endDate + '&id=' + id);
  }

  getLeadswAgent(searchInput: any): Observable<Logs[]> {
    let startDate = '';
    let endDate = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1].getTime() + (24 * 60 * 60 * 1000) - 1000);
          endDate = endDateTime.toISOString();
        }
        if (startDate && !endDate) {
          const startDateTime = new Date(searchInput.date[0]);
          endDate = new Date(startDateTime.getTime() + 24 * 60 * 60 * 1000 - 1000).toISOString();
        }
      }
    return this.http.get<Logs[]>(BACKEND_URL + '/admin/logs/leadswagent?startDate=' + startDate + '&endDate=' + endDate);
  }

  getLeadswDate(searchInput: any): Observable<Logs[]> {
    let startDate = '';
    let endDate = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1].getTime() + (24 * 60 * 60 * 1000) - 1000);
          endDate = endDateTime.toISOString();
        }
        if (startDate && !endDate) {
          const startDateTime = new Date(searchInput.date[0]);
          endDate = new Date(startDateTime.getTime() + 24 * 60 * 60 * 1000 - 1000).toISOString();
        }
      }
    return this.http.get<Logs[]>(BACKEND_URL + '/admin/logs/leadswdate?startDate=' + startDate + '&endDate=' + endDate);
  }

  getAgentsDeals(searchInput: any): Observable<Logs[]> {
    let startDate = '';
    let endDate = '';
    let agent = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1].getTime() + (24 * 60 * 60 * 1000) - 1000);
          endDate = endDateTime.toISOString();
        }
        if (startDate && !endDate) {
          const startDateTime = new Date(searchInput.date[0]);
          endDate = new Date(startDateTime.getTime() + 24 * 60 * 60 * 1000 - 1000).toISOString();
        }      
      }
      if(searchInput.agent){
        agent = searchInput.agent
      }
    return this.http.get<Logs[]>(BACKEND_URL + `/admin/dashboard/deals?agent=${agent}`);
  }

  getLogswPagination(currentPage: number, pageLimit: number): Observable<LogsWPagination> {
    return this.http.get<LogsWPagination>(BACKEND_URL + '/admin/logs/allwPg?page=' + currentPage + '&pageLimit=' + pageLimit);
  }

  getPeopleswPagination(currentPage: number, pageLimit: number): Observable<PeoplesWPagination> {
    return this.http.get<PeoplesWPagination>(BACKEND_URL + '/v1/person/all?page=' + currentPage + '&pageLimit=' + pageLimit);
  }

  getOfferswPagination(currentPage: number, pageLimit: number): Observable<OffersWPagination> {
    return this.http.get<OffersWPagination>(BACKEND_URL + '/admin/offers/all?page=' + currentPage + '&pageLimit=' + pageLimit);
  }

  searchLogs(currentPage: number, pageLimit: number, searchInput: any): Observable<searchLogs> {
    const type = searchInput.type == undefined && searchInput.type !== null ? '' : searchInput.type; 
    const user = searchInput.user == undefined && searchInput.user !== null ? '' : searchInput.user; 
    const agent = searchInput.agent == undefined && searchInput.agent !== null ? '' : searchInput.agent; 
    let startDate = '';
    let endDate = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1]);
          endDate = endDateTime.toISOString();
        }
      }
    return this.http.get<searchLogs>(BACKEND_URL + '/admin/logs/search?page=' + currentPage + '&pageLimit=' + pageLimit + '&type=' + type + '&user=' + user + '&agent=' + agent + '&startDate=' + startDate + '&endDate=' + endDate);
  }

  searchPeoples(currentPage: number, pageLimit: number, searchInput: any): Observable<searchPeoples> {
    const name = searchInput.name == undefined && searchInput.name !== null ? '' : searchInput.name; 
    const phone = searchInput.phone == undefined && searchInput.phone !== null ? '' : searchInput.phone; 
    const agent = searchInput.agentId == undefined && searchInput.agentId !== null ? '' : searchInput.agentId; 
    let startDate = '';
    let endDate = '';
      if (searchInput.date) {
        if (searchInput.date[0] != null) {
          const startDateTime = new Date(searchInput.date[0]);
          startDate = startDateTime.toISOString();
        }
        if (searchInput.date[1] != null) {
          const endDateTime = new Date(searchInput.date[1]);
          endDate = endDateTime.toISOString();
        }
      }
    return this.http.get<searchPeoples>(BACKEND_URL + '/v1/person/search?page=' + currentPage + '&pageLimit=' + pageLimit + '&name=' + name + '&phone=' + phone + '&agentId=' + agent + '&startDate=' + startDate + '&endDate=' + endDate);
  }
  changeAgent(data: any): Observable<People[]> {
    return this.http.put<People[]>(BACKEND_URL + '/v1/person/change', data);
  }

  createTeam(data: any): Observable<Teams[]> {
    return this.http.post<Teams[]>(BACKEND_URL + '/admin/teams', data);
  }

  deleteTeam(teamId: any): Observable<Teams[]> {
    return this.http.delete<Teams[]>(BACKEND_URL + '/admin/teams/' + teamId);
  }

  updateTeams(data: any): Observable<Teams[]> {
    return this.http.put<Teams[]>(BACKEND_URL + '/admin/teams', data);
  }

  getStages(): Observable<any> {
    return this.http.get<any>(BACKEND_URL + '/admin/stages/all');
  }

  getUsers(): Observable<Users[]> {
    return this.http.get<Users[]>(BACKEND_URL + '/admin/users');
  }

  createUser(data: any): Observable<Users[]> {
    return this.http.post<Users[]>(BACKEND_URL + '/admin/user/register', data);
  }

  getAgentByID(agentId: any): Observable<Agents[]> {
    return this.http.get<Agents[]>(BACKEND_URL + '/admin/agent/' + agentId);
  }

}
