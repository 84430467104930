import { Component, OnInit } from "@angular/core";
import { AdminService } from "../_services/admin.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../_services/auth.service";
import { PhoneValidator } from "../_util/phone.validator";
import { NotificationService } from "../_services/notification.service";
import * as XLSX from "xlsx";
import { ConfirmationService, SelectItem } from "primeng/api";
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
  providers: [ConfirmationService],
})
export class AgentsComponent implements OnInit {
  tableData: any[] = [];
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  createAgentForm: FormGroup;
  updateAgentForm: FormGroup;
  formSubmitted = false;
  companyId = this.authService.getCompany();
  preDays: any = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  days: SelectItem[] = [
    { label: 'Monday', value: { name: 'monday' } },
    { label: 'Tuesday', value: { name: 'tuesday' } },
    { label: 'Wednesday', value: { name: 'wednesday' } },
    { label: 'Thursday', value: { name: 'thursday' } },
    { label: 'Friday', value: { name: 'friday' } },
    { label: 'Saturday', value: { name: 'saturday' } },
    { label: 'Sunday', value: { name: 'sunday' } },
  ];
  workingDaysData: any;
  workingDaysCols: any[];
  pipelines: SelectItem[] = [];

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'E-mail' },
      { field: 'pipelines', header: 'Pipelines' },
      { field: 'days', header: 'Days' },
      { field: 'dailyLeadLimit', header: 'Daily Lead Limit' }
    ];
    this.workingDaysCols = [
      { field: 'active', header: 'Active' },
      { field: 'dayName', header: 'Day' },
      // { field: "startTime", header: "Start Time" },
      // { field: "endTime", header: "End Time" },
    ];
    this.getAgents();
    this.updateAgentForm = this.formBuilder.group({
      _id: [''],
      email: [''],
      pipelines: [''],
      name: [''],
      pipedriveUserId: [''],
      dailyLeadLimit: [0]
    });
    this.getPipelines();
  }

  getAgents() {
    this.tableData = []
    this.isLoading = true;
    this.adminService.getAgents().subscribe((data) => {
      data.map((item: any) => {
        if (!item.hidden) {
          this.tableData.push(item);
        }
      });
      this.tableData.reverse();
      this.isLoading = false;
    });
  }

  addAgent() {
    this.addModal = true;
  }

  checkAgent() {
    this.isLoading = true;
    this.adminService.checkAgents().subscribe(() => {
      this.isLoading = false;
    });
  }

  editRow(data) {
    let days = [];
    if (data.days.length > 0) {
      data.days.map((day) => {
        days.push({
          active: day.active,
          dayName: day.dayName,
        });
      });
    } else {
      const preDays = [
        {
          active: false,
          dayName: 'Monday',
        },
        {
          active: false,
          dayName: 'Tuesday',
        },
        {
          active: false,
          dayName: 'Wednesday',
        },
        {
          active: false,
          dayName: 'Thursday',
        },
        {
          active: false,
          dayName: 'Friday',
        },
        {
          active: false,
          dayName: 'Saturday',
        },
        {
          active: false,
          dayName: 'Sunday',
        },
      ];
      preDays.map((day) => {
        days.push({
          active: day.active,
          dayName: day.dayName,
        });
      });
    }
    const pipelinesIds = [];
    data.pipelines.map((pipeline) => {
      pipelinesIds.push(pipeline._id);
    });
    const value = {
      _id: data._id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      pipelines: pipelinesIds,
      pipedriveUserId: data.agentId,
      dailyLeadLimit: data.dailyLeadLimit
    };
    this.workingDaysData = days;
    this.updateAgentForm.patchValue(value);
    this.editModal = true;
  }

  checkAgents() {
    this.formSubmitted = true;
    const data = {
      _id: this.updateAgentForm.value._id,
      days: this.workingDaysData,
      pipelines: this.updateAgentForm.value.pipelines,
      dailyLeadLimit: this.updateAgentForm.value.dailyLeadLimit
    };
    if (this.updateAgentForm.valid) {
      this.adminService
        .checkAgentfAdmin(data, this.updateAgentForm.value.pipedriveUserId)
        .subscribe(
          (res) => {
            console.log('XXxxXX', res);
            this.formSubmitted = false;
            this.isLoading = false;
            this.editModal = false;
            this.getAgents();
            this.updateAgentForm.reset();
            this.workingDaysData = [];
          },
          (err) => {
            console.log('error', err);
            if (this.tableData.length > 1) {
              this.confirmationService.confirm({
                message: err.error.warning,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                  this.adminService
                    .updateAgent(
                      data,
                      this.updateAgentForm.value.pipedriveUserId
                    )
                    .subscribe(
                      () => {
                        this.editModal = false;
                        this.formSubmitted = false;
                        this.isLoading = false;
                        this.getAgents();
                        this.updateAgentForm.reset();
                        this.workingDaysData = [];
                      },
                      () => {
                        this.formSubmitted = false;
                        this.isLoading = false;
                      }
                    );
                },
              });
            } else {
              this.notificationService.showError(
                'To delete a agent, please add a new agent first.'
              );
            }
          }
        );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateAgent() {
    const data = {
      _id: this.updateAgentForm.value._id,
      days: this.workingDaysData,
      pipelines: this.updateAgentForm.value.pipelines,
    };
    if (this.updateAgentForm.valid) {
      this.isLoading = true;
      this.adminService
        .updateAgent(data, this.updateAgentForm.value.pipedriveUserId)
        .subscribe(
          () => {
            this.formSubmitted = false;
            this.isLoading = false;
            this.editModal = false;
            this.getAgents();
            this.updateAgentForm.reset();
            this.workingDaysData = [];
          },
          () => {
            this.formSubmitted = false;
            this.isLoading = false;
          }
        );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  deleteRow(data) {
    if (this.tableData.length > 1) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the agent?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.adminService.deleteAgent(data.agentId).subscribe(() => {
            this.getAgents();
          });
        },
      });
    } else {
      this.notificationService.showError(
        'To delete a agent, please add a new agent first.'
      );
    }
  }

  getPipelines() {
    this.adminService.getPipelines().subscribe((data) => {
      this.pipelines = data.map((pipe) => {
        return { label: pipe.name, value: pipe._id };
      });
    });
  }
}
